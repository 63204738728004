<template>
    <div class="row" style="margin-left: auto;">
    </div>
    <!-- end heading -->
    <div class="container-preview mt-5 d-none d-lg-block"></div>
      <div class="row d-none d-lg-block">
          <div class="col-sm-12 clinicCard-Box"></div>
      </div>
      <div class="d-block d-lg-none">
        <div class="row">
            <div class="col-10 col-sm-9 col-lg-10 mb-0">
        <p class="clinic-name" style="margin-left: 20px;">{{ this.clinicName }}</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2 mb-0 ">
        <p class="closeButton" @click="clinicPreview()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
        </div>
        <hr class="horizonal-lineTag">
      </div>
    <div class="previewContent" style="background-color: #ffff;">
        <div class="d-none d-lg-block">
        <div class="row ">
            <div class="col-10 col-sm-9 col-lg-10 mb-0">
        <p class="clinic-name" style="margin-left: 20px;">{{ this.clinicName }}</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2 mb-0 ">
        <p class="closeButton mt-0" @click="clinicPreview()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
        </div>
        <hr class="horizonal-lineTag">
        </div>
        <div class="row details-Clinic">
    <div class="col-5 mt-3">
        <div class="form-group">
            <img src="~@/assets/img/images/doctor.png" id="preview_img" class="preview-doctor-img">
        </div>
    </div>
    <!-- FOR DESKTOP  -->
    <div class="col-7 mt-3 d-none d-lg-block">
        <h4 class="mt-3 clinicDoctorDetails">Clinic address :<span class="inputContent" style="color: gray;">
    <!-- <div class="col-lg-6 col-md-4 col-12 mt-0 mb-3"> -->
    {{ this.doorNo }}
    {{ this.district }}
    {{ this.landmark }}
    {{ this.pincode }}
    {{ this.state }}
    {{ this.street }}
    <!-- </div> -->
    </span> </h4>
            </div>
                </div>
<div class="d-none d-lg-block">
    <div class="row">
        <div class="col-5">
            <label for="" class="doctor-name" > {{ userPayload.firstName }} {{
                userPayload.lastName }}</label>
            <h4 class="clinicDoctorDetails">Educational qualification :
                    <p class="inputContent">{{userPayload.educational }}</p></h4>
        </div>
        <div class=" col-7 ">
        <h4 class="clinicDoctorDetails">Contact number :
            <span class="inputContent">{{ this.mobile }}</span>
        </h4>
        <h4 class="clinicDoctorDetails" v-if="this.website">Clinic website :
            <span class="inputContent"  >
                <span >
                    {{ this.website}}
                </span>
            </span>
        </h4>
        </div>
    </div>
        </div>
        <!-- FOR MOBILE -->
        <div class="d-block d-lg-none">
            <label for="" class="doctor-name">{{ userPayload.firstName }} {{
                userPayload.lastName }}</label>
            <h4 class="clinicDoctorDetails mt-3">Educational qualification : <span class="inputContent">{{
                userPayload.educational }}</span></h4>
            <h4 class="mt-3 clinicDoctorDetails">Clinic address :</h4>
            <span class="inputContent" style="color: gray;">
    <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
      {{ this.doorNo }}
      {{ this.district }}
      {{ this.landmark }}
      {{ this.pincode }}
      {{ this.state }}
      {{ this.street }}
    </div>
      </span>
        <div class="mt-3">
        <h4 class="clinicDoctorDetails">Contact number :
            <span class="inputContent">{{ this.mobile }}</span>
        </h4>
        <h4 class="clinicDoctorDetails"  v-if="this.website">Clinic website :
            <span class="inputContent" >
                {{ this.website}}
                </span>
        </h4>
            </div>
        </div>
        <hr class="horizonal-lineTag">
        <div class="row">
            <!-- FOR DESKTOP  -->
            <div class="col-12 doctor-info d-none d-lg-block">
                <h4>Consult for:</h4>
                <p class="consultations">{{ practiceAreas }}</p>
                <h4 class="mt-3">Consultation offered:</h4>
                <p class="consultations">{{ offerConsultations }}</p>
                <h4 class="" v-if="userPayload.information !=null">Additional information : <span class="doctorDetails">{{
                    userPayload.information }}</span></h4>
                <p></p>
            </div>
            <!-- FOR MOBILE  -->
            <div class="col-12 doctor-info d-block d-lg-none ">
                <h4>Consult for:</h4>
                <p class="consultations mb-0">{{ practiceAreas }}</p>
                <h4 class="mt-3">Consultation offered:</h4>
                <p class="consultations mb-0">{{ offerConsultations }}</p>
                <h4 class="" v-if="userPayload.information !=null">Additional information : <span class="doctorDetails">
                        {{ userPayload.information }}</span>
                </h4>
            </div>
            <h4 class="" v-if="getPincodeData.length || homeConsult.length">Also : 
                <span   class="doctorDetails" style="color: gray;">
            <span class="contact-details" v-for="index in getPincodeData" :key="index" @click="sign(index)"><a href="">@Clinic {{
                index.pincode }}</a></span>
                <span class="contact-details" v-for="index in homeConsult" :key="index" @click="homeclinicsign(index)"><a href="">Home Consulation</a></span>
                </span></h4>
        </div>
        <hr class="horizonal-lineTag">
    </div>
</template>
<script>
import axios from 'axios';
export default {
    components: {
    },
    data() {
        return {
            userPayload: {},
            userRecords: [],
            homeConsult: [],
            clinicConsult: [],
            issign: false,
            clinicID: Number,
            doctorId: null,
            offerConsultations: "",
            practiceAreas: "",
            doorNo: '',
            district:'',
            landmark: '',
            pincode: '',
            state: '',
            street: '',
            getPincodes: '',
            getPincodeData: [], 
        }
    },
    created: function () {
        const clinicId = this.$route.query
        this.clinicID = clinicId;
        this.getConsultation();
    },
    methods: {
        sign(index) {
      const clinicID = index.id
      const doctorId =index.hcps_id.id
      window.open(`/user/preview?hcpId=${doctorId}&clinicId=${clinicID}`)
    },
    homeclinicsign(index){
      const homeId = index.id
      const doctorId =index.hcps_id.id

      window.open(`/homeConsultation/preview?hcpId=${doctorId}&homeId=${homeId}`)
    },
        clinicPreview() {
            window.scrollTo(0, 0);
            this.$router.push('/user/previewcard')
        },
        async getConsultation() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/clinic-consultation`)
                .then(async (response) => {
                    this.getPincodes = [];
                    this.clinicConsult = response.data;
                     this.clinicConsult.map(async (datas) => {
                            if (datas.id === parseInt(this.clinicID.clinicId)) {
                                this.doctorId = datas?.hcps_id?.id;
                                this.clinicName = datas.clinic_name;
                                this.website = datas.clinic_website;
                                this.address = datas.address;
                                this.pincode = datas.pincode;
                                this.mobile = datas.mobile;
                                this.doorNo = datas.doorNo;
                                this.district = datas.district;
                                this.landmark = datas.landmark;
                                this.pincode = datas.pincode;
                                this.state = datas.state;
                                this.street = datas.street;
                            }
                            else {
                            this.getPincodes.push(datas);
                        }
                        })
                    this.getPincodeData = this.getPincodes.filter(item => item?.hcps_id?.id === this.doctorId);
                })
            axios
                .get(`${BASE_API_URL}/home-consultation`)
                .then((response) => {
                    this.homeConsult = response.data.filter(item => item?.hcps_id?.id == this.doctorId);
                })

            axios
                .get(`${BASE_API_URL}/hcps/${this.doctorId}/one`)
                .then((response) => {
                    this.userPayload = response.data;
                    const consultationArray = this.userPayload.Doctorconsultaion || [];
                    this.offerConsultations = consultationArray.map(item => item.consultation).join(', ');
                    const practiceAreasArray = this.userPayload.practiceId ;
                    this.practiceAreas = practiceAreasArray.map(item => item.practiceArea).join(', ');
                    
                })
        },

    },
}
</script>
<style>
span.contact-details {
    font-size: 18px;
    color: gray;
    margin: 10px;
}
.clinicCard-Box {
  padding: 10px;
  margin-top: 10px
}
.text-green-color{
  font-size:20px;
  color: #00979e;
  cursor: pointer;
}
.inputContent {
    color: gray;
}

p.consultations {
    font-size: 18px;
    color: gray;
}

span.doctorDetails {
    font-size: 18px;
    color: #2c3e50;
}

.listClinics {
    float: right;
}

hr.horizonal-lineTag {
    margin: 0px;
}

.clinic-name {
    font-size: 25px;
    font-weight: 550;
    /* margin-bottom: 0px; */
}

.previewContent {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 1.5rem;
}

/* .container {
    max-width: 80%;
    margin: auto;
} */

h4 {
    font-size: 20px;
}

.doctor-info h4 {
    margin-top: 20px;
}

.doctor-name {
    margin-top: 5px;
    font-size: 18px;
    color: #000;
}

@media screen and (max-width:992px) {
    .clinic-name {
    margin-left: 20px;
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }
    .previewContent {
        width: 100%;
        margin-top: 0px;
        box-shadow: none;
        margin-left: auto;
        margin-right: auto;
        padding:0rem 1.5rem 1.5rem 1.5rem;
    }
}

@media screen and (max-width:576px) {
    .doctor-name {
        font-size: 14px;
        margin-top: 0px;
    }

    h4.clinicDoctorDetails {
        font-size: 16px;
    }

    span.doctorDetails {
        font-size: 16px;
    }

    p.consultations {
        font-size: 16px;
        margin-top: 10px;
    }

    .inputContent {
        font-size: 16px;
        color: gray;
    }
}
</style>
